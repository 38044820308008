/* Medium screen  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.feature-one__single {
			padding: 40px 30px 50px;
	}

	.welcome-one__left {
			margin-left: 0;
	}

	.welcome-one__right {
			max-width: 600px;
			margin-top: 50px;
			margin-left: 0;
	}

	.counter-one__inner {
			padding: 57px 30px 57px;
	}

	.contact-one__left:before {
			right: -157px;
	}

	.contact-one__right {
			margin-right: -556px;
	}

	.contact-one-shape-4 {
			display: none;
	}

	.blog-one__content {
			padding: 22px 20px 20px;
	}

	.information__inner {
			margin-left: 30px;
			margin-right: 30px;
	}

	.information__inner:before {
			left: -30px;
			right: -30px;
	}

	.footer-widget__newsletter {
			margin-left: 0;
			margin-top: 34px;
	}

	.footer-widget__articles {
			margin-right: 0;
			margin-top: 34px;
	}

	.feature-two__single {
			max-width: 370px;
			margin-left: auto;
			margin-right: auto;
	}

	.we-cleaning__left {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
	}

	.we-cleaning__right {
			max-width: 600px;
			margin: 80px auto 0;
	}

	.we-cleaning-line {
			top: -30px;
	}

	.we-cleaning-line:after {
			bottom: -37px;
	}

	.services-two__content {
			padding: 50px 30px 40px;
	}

	.project-one__right {
			margin-left: 0;
	}

	.process__single {
			padding: 0 35px 0;
	}

	.process-line img {
			width: 75%;
	}

	.call-one__text {
			font-size: 30px;
	}

	.why-choose-one__right {
			max-width: 600px;
	}

	.why-choose-one {
			padding: 0 0 112px;
	}

	.why-choose-one-bubble-2,
	.why-choose-one-bubble-3 {
			display: none;
	}

	.why-choose-two__left {
			max-width: 600px;
			margin: 0 auto;
	}

	.why-choose-two__right {
			max-width: 600px;
			margin: 60px auto 0;
	}

	.project-two__top-right {
			margin-left: 0;
	}

	.feature-three__single {
			padding: 59px 29px 0;
	}

	.feature-three__points {
			float: none;
	}

	.feature-three__points-two {
			margin-left: 0;
			margin-top: 9px;
	}

	.blog-three__content {
			padding-left: 35px;
			padding-right: 35px;
	}

	.get-to-know__left {
			max-width: 100%;
			margin: 0 auto 0;
	}

	.get-to-know__right {
			max-width: 100%;
			/* margin: 60px auto 0; */
	}

	.get-to-know__img:before {
			bottom: 28px;
			right: 29px;
	}

	.service-details__benefits-right {
			margin-top: 24px;
	}

	.team-details__top-right {
			margin-left: 0;
	}

	.team-details__top-left {
			margin-right: 0;
	}

	.team-details__bottom-left {
			margin-right: 0;
	}

	.team-details__bottom-right {
			margin-left: 0;
	}

	.error-page__inner {
			margin-right: 0;
	}

	.error-page__img {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
	}

	.portfolio-details__points-box {
			margin-top: -9px;
			margin-left: 0;
	}

	.blog-details__pagenation li+li {
			margin-left: 0;
			margin-top: 30px;
	}
}




/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.welcome-one__left {
			margin-left: 0;
	}
	.m2{
			margin: 0;
	}
	.welcome-one__right {
			max-width: 600px;
			margin-top: 50px;
			margin-left: 0;
	}

	.services-one__single {
			margin-left: auto;
			margin-right: auto;
	}

	.counter-one__list {
			justify-content: inherit;
			flex-wrap: wrap;
	}

	.counter-one__list li {
			flex: 0 0 50%;
			max-width: 50%;
			display: inherit;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-bottom: 33px;
	}

	.counter-one__inner {
			padding: 57px 30px 24px;
	}

	.contact-one-shape-4 {
			display: none;
	}

	.contact-one__left {
			margin-right: 0;
	}

	.contact-one__left:before {
			transform: skewX(0deg);
	}

	.contact-one__right {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
	}

	.contact-one__img {
			clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
	}

	.contact-one-shape-1,
	.contact-one-shape-2,
	.contact-one-shape-3 {
			display: none;
	}

	.blog-one {
			margin-top: 0;
	}

	.information__list {
			flex-direction: column;
			align-items: flex-start;
	}

	.information__list li+li {
			margin-top: 16px;
	}

	.information__inner {
			padding: 96px 0px 73px;
	}

	.footer-widget__newsletter {
			margin-left: 0;
			margin-top: 34px;
	}

	.footer-widget__articles {
			margin-right: 0;
			margin-top: 34px;
	}

	.we-cleaning__left {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
	}

	.we-cleaning__right {
			max-width: 600px;
			margin: 80px auto 0;
	}

	.we-cleaning-line {
			top: -30px;
	}

	.we-cleaning-line:after {
			bottom: -37px;
	}

	.project-one__left .section-title {
			margin-bottom: 37px;
	}

	.project-one__right {
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 50px;
	}

	.process-line {
			display: none;
	}

	.call-one__inner {
			justify-content: center;
			flex-direction: column;
			text-align: center;
	}

	.call-one__text-box {
			margin-bottom: 18px;
	}

	.why-choose-one {
			padding: 0 0 112px;
	}

	.why-choose-one-bubble-2,
	.why-choose-one-bubble-3 {
			display: none;
	}

	.why-choose-two__left {
			max-width: 600px;
			margin: 0 auto;
	}

	.why-choose-two__right {
			max-width: 600px;
			margin: 60px auto 0;
	}

	.project-two__top-left .section-title {
			margin-bottom: 37px;
	}

	.project-two__top-right {
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 50px;
	}

	.counter-two__list {
			justify-content: center;
			flex-wrap: wrap;
	}

	.counter-two__list li {
			flex: 0 0 50%;
			max-width: 50%;
			width: 100%;
			justify-content: center;
			margin-bottom: 34px;
	}

	.counter-two__inner {
			padding: 93px 60px 66px;
	}

	.cta-one__title br {
			display: none;
	}

	.get-to-know__left {
			max-width: 100%;
			margin: 0 auto 0;
	}

	.get-to-know__right {
			max-width: 680px;
			/* margin: 60px auto 0; */
	}

	.get-to-know__img:before {
			bottom: 28px;
			right: 29px;
	}

	.testimonial-two__container .testimonial-one__single {
			padding: 40px 45px 35px;
	}

	.service-details__left {
			margin-bottom: 60px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
	}
	.service-details__need-help{
			margin-top: 0;
			width: 48%;
	}
	.service-details__points-box{
			width: 100%;
			margin-top: 2em;
	}
	.service-details__points{
			display: flex;
			flex-direction: row !important;
	}
	.service-details__points li{
			width: 50%;
	}
	.service-details__category{
			width: 48%;
	}
	.service-details__btn {
			width: auto;
	}

	.service-details__benefits-right {
			margin-top: 24px;
	}

	.team-details__top-right {
			margin-left: 0;
	}

	.team-details__top-left {
			margin-right: 0;
	}

	.team-details__top-content {
			margin-top: 49px;
	}

	.team-details__bottom-left {
			margin-right: 0;
	}

	.team-details__bottom-right {
			margin-left: 0;
			margin-top: 30px;
	}

	.testimonial-page .testimonial-one__single {
			padding: 40px 45px 35px;
	}

	.testimonial-carousel-page .testimonial-one__single {
			padding: 40px 24px 35px;
	}

	.faq-page__right {
			margin-top: 60px;
	}

	.error-page__img {
			margin-left: 0;
			margin-right: 0;
			margin-top: 50px;
	}

	.portfolio-details__points-box {
			margin-top: 30px;
			margin-left: 0;
	}

	.blog-details__pagenation li+li {
			margin-left: 0;
			margin-top: 30px;
	}

	.contact-page-one-details__list li {
			flex: 0 0 100%;
			max-width: 100%;
	}

	.contact-page-one-details__list li:before {
			display: none;
	}

	.contact-page-two__left {
			margin-bottom: 60px;
	}


}


/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
	.section-title__title {
			font-size: 35px;
			line-height: 45px;
	}
	.m2{
			margin: 0;
	}
	.section-title__title br {
			display: none;
	}

	.feature-one__single {
			padding: 40px 30px 50px;
	}

	.welcome-one__left {
			margin-left: 0;
			min-height: 300px;
	}

	.welcome-one__bg {
			display: none;
	}

	.welcome-one__circle-one {
			display: none;
	}

	.welcome-one__img-1 {
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			text-align: center;
	}

	.welcome-one__img-1>img {
			width: 100%;
	}

	.welcome-one__small-img-1 {
			top: 500px;
			left: 0;
	}

	.welcome-one__small-img-2 {
			top: -162px;
			right: 0;
	}

	.welcome-one__right {
			margin-top: 498px;
			margin-left: 0;
	}

	.welcome-one__points {
			float: none;
	}

	.welcome-one__points-two {
			margin-left: 0;
			margin-top: 9px;
	}

	.welcome-one__btn-call-box {
			flex-direction: column;
			align-items: baseline;
	}

	.welcome-one__call-box {
			margin-left: 0;
			margin-top: 20px;
	}

	.services-one__single {
			margin-left: auto;
			margin-right: auto;
	}

	.counter-one__list {
			justify-content: inherit;
			flex-wrap: wrap;
	}

	.counter-one__list li {
			flex: 0 0 100%;
			max-width: 100%;
			display: inherit;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-bottom: 33px;
	}

	.counter-one__inner {
			padding: 57px 30px 24px;
	}

	.testimonial-one__carousel.owl-theme .owl-nav {
			display: none;
	}

	.testimonial-one__single {
			padding: 40px 30px 35px;
	}

	.testimonial-one__client-details {
			padding-left: 103px;
	}

	.testimonial-one__client-img {
			left: 30px;
	}

	.contact-one-shape-4 {
			display: none;
	}

	.contact-one__left {
			margin-right: 0;
	}

	.contact-one__left:before {
			transform: skewX(0deg);
	}

	.contact-one__right {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
	}

	.contact-one__img {
			clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
	}

	.contact-one-shape-1,
	.contact-one-shape-2,
	.contact-one-shape-3 {
			display: none;
	}

	.blog-one {
			margin-top: 0;
	}

	.contact-one__call {
			display: none;
	}

	.blog-one__content {
			padding: 22px 20px 20px;
	}

	.blog-one__carousel.owl-theme .owl-nav {
			display: none;
	}

	.information__list {
			flex-direction: column;
			align-items: flex-start;
			margin-left: 1em;
	}

	.information__list li+li {
			margin-top: 16px;
	}

	.information__inner {
			padding: 96px 0px 73px;
			margin-left: 0px;
			margin-right: 0px;
	}

	.information__inner:before {
			left: 0px;
			right: 0px;
	}

	.information__icon span {
			font-size: 35px;
	}

	.information__number {
			font-size: 12px;
	}

	.footer-widget__newsletter {
			margin-left: 0;
			margin-top: 44px;
	}

	.footer-widget__articles {
			margin-right: 0;
			margin-top: 34px;
	}

	.footer-widget__links {
			margin-top: 44px;
	}

	.we-cleaning__small-img {
			display: none;
	}

	.we-cleaning-line {
			display: none;
	}

	.we-cleaning__right {
			margin-left: 0;
			margin-top: 60px;
	}

	.we-cleaning__left {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
	}

	.we-cleaning__video-link {
			left: 0;
	}

	.we-cleaning__shape-1 {
			display: none;
	}

	.we-cleaning__points li {
			float: none;
	}

	.we-cleaning__points li:last-child {
			margin-left: 0;
			margin-top: 40px;
	}

	.services-two__content {
			padding: 50px 30px 40px;
	}

	.faq-one-bg {
			display: none;
	}

	.faq-one-accrodion .accrodion-title h4::before {
			right: -20px;
	}

	.project-one__left .section-title {
			margin-bottom: 37px;
	}

	.project-one__right {
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 50px;
	}

	.process__single {
			padding: 0 35px 0;
	}

	.process-line {
			display: none;
	}

	.call-one__inner {
			justify-content: center;
			flex-direction: column;
			text-align: center;
	}

	.call-one__text-box {
			margin-bottom: 18px;
	}

	.why-choose-one {
			padding: 0 0 112px;
	}

	.why-choose-one-bubble-1,
	.why-choose-one-bubble-2,
	.why-choose-one-bubble-3 {
			display: none;
	}

	.why-choose-one__img-2 {
			margin-left: 0;
	}

	.why-choose-one__img-3 {
			margin-left: 0;
	}

	.why-choose-two__left {
			margin-left: 0;
	}

	.why-choose-two__img-2 {
			display: none;
	}

	.why-choose-two__dot {
			display: none;
	}

	.why-choose-two__right {
			margin-left: 0;
			margin-top: 60px;
	}

	.project-two__top-left .section-title {
			margin-bottom: 37px;
	}

	.project-two__top-right {
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 50px;
	}

	.feature-three__single {
			padding: 59px 29px 0;
	}

	.feature-three__points {
			float: none;
	}

	.feature-three__points-two {
			margin-left: 0;
			margin-top: 9px;
	}

	.feature-three__top {
			flex-direction: column;
			align-items: baseline;
	}

	.feature-three__content {
			margin-left: 0;
			margin-top: 30px;
	}

	.feature-three__btn {
			padding-left: 45px;
	}

	.counter-two__list {
			justify-content: center;
			flex-wrap: wrap;
	}

	.counter-two__list li {
			flex: 0 0 100%;
			max-width: 100%;
			width: 100%;
			justify-content: center;
			margin-bottom: 34px;
	}

	.counter-two__inner {
			padding: 93px 30px 66px;
	}

	.cta-one__title br {
			display: none;
	}

	.cta-one__title {
			font-size: 33px;
			line-height: 43px;
	}

	.cta-one__title span:before {
			display: none;
	}

	.cta-one__btn-box {
			flex-direction: column;
	}

	.cta-one__btn-1+.cta-one__btn-2 {
			margin-left: 0;
			margin-top: 10px;
	}

	.blog-three__carousel.owl-theme .owl-nav {
			display: none;
	}

	.blog-three__single {
			flex-direction: column;
			align-items: baseline;
			padding: 0 20px;
	}

	.blog-three__single:before {
			left: 0;
	}

	.blog-three__content {
			padding-left: 0px;
			padding-right: 0px;
			margin-top: 20px;
	}
	.get-to-know{
			padding-top: 60px;
	}
	.managers-details{
			width: 100%;
	}
	.get-to-know__left {
			margin-left: 0;
			margin-right: 0;
			flex-direction: column;
	}
	.managers-image{
			width: 100%;
	}

	.get-to-know__right {
			margin-top: 60px;
	}

	.get-to-know__points {
			float: none;
	}

	.get-to-know__points--two {
			margin-left: 0;
			margin-top: 10px;
	}
	.get-to-know__btn{
			margin-left: 1em;
	}
	.testimonial-two__container .testimonial-one__single {
			padding: 40px 24px 35px;
	}

	.service-details__left {
			margin-bottom: 60px;
	}

	.service-details__btn {
			width: auto;
	}

	.service-details__benefits-right {
			margin-top: 24px;
	}

	.service-details__need-help {
			padding: 50px 25px 50px;
	}

	.service-details__btn {
			width: auto;
			padding-left: 25px;
	}

	.service-details__btn i {
			margin-left: 15px;
	}

	.service-details__points {
			flex-direction: column;
	}

	.service-details__points li+li {
			margin-left: 0;
			margin-top: 30px;
	}

	.team-details__top-right {
			margin-left: 0;
	}

	.team-details__top-left {
			margin-right: 0;
	}

	.team-details__top-content {
			margin-top: 49px;
	}

	.team-details__bottom-left {
			margin-right: 0;
	}

	.team-details__bottom-right {
			margin-left: 0;
			margin-top: 30px;
	}

	.testimonial-page .testimonial-one__single {
			padding: 40px 24px 35px;
	}

	.testimonial-carousel-page .testimonial-one__single {
			padding: 40px 24px 35px;
	}

	.faq-page__right {
			margin-top: 60px;
	}

	.error-page__img {
			margin-left: 0;
			margin-right: 0;
			margin-top: 50px;
	}

	.error-page__inner {
			margin-right: 0;
	}

	.error-page__title {
			font-size: 155px;
			line-height: 155px;
	}

	.error-page {
			padding: 99px 0 170px;
	}

	.portfolio-details__details-box {
			position: relative;
			bottom: 0;
			right: 0;
			margin-top: 30px;
	}

	.portfolio-details__points-box {
			margin-top: 30px;
			margin-left: 0;
	}

	.blog-sidebar__bottom-box {
			padding: 50px 20px 45px;
	}

	.blog-sidebar__delivering-services {
			padding: 50px 20px 47px;
	}

	.sidebar__post {
			padding: 45px 20px 32px;
	}

	.sidebar__category {
			padding: 46px 20px 33px;
	}

	.sidebar__tags {
			padding: 45px 15px 50px;
	}

	.sidebar__comments {
			padding: 45px 20px 43px;
	}

	.blog-details__bottom {
			flex-direction: column;
	}

	.blog-details__tags a {
			padding: 6px 20px 4px;
	}

	.blog-details__social-list {
			margin-top: 25px;
	}

	.blog-details__pagenation li+li {
			margin-left: 0;
			margin-top: 30px;
	}

	.blog-details__pagenation li {
			padding-left: 25px;
			padding-right: 25px;
	}

	.comment-one__single {
			flex-direction: column;
	}

	.comment-one__content {
			margin-left: 0;
			margin-top: 35px;
	}

	.contact-page-one-details__list li {
			flex: 0 0 100%;
			max-width: 100%;
	}

	.contact-page-one-details__list li:before {
			display: none;
	}

	.contact-page-two__left {
			margin-bottom: 60px;
	}

	.page-header-bg {
			display: none;
	}

	.page-header__inner h2 {
			font-size: 27px;
			line-height: 37px;
	}
	.details-grid{
			grid-template-columns: repeat(1, minmax(200px, 1fr));
			place-content: center;
			align-items: center;
	}
	.detail-card img{
			width: 100%;
	}

}
@media only screen and (max-width: 650px) {
}


@media only screen and (min-width: 1500px) and (max-width: 1835px) {
	.contact-one-shape-4 {
			display: none;
	}

	.contact-one__call {
			right: 260px;
	}


}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.contact-one-shape-4 {
			display: none;
	}

	.contact-one__call {
			right: 410px;
	}


}


@media only screen and (min-width: 1200px) and (max-width: 1545px) {
	.why-choose-one-shape-1 {
			display: none;
	}


}









/*--------------------------------------------------------------
# Slider All Responsice Css
--------------------------------------------------------------*/
@media only screen and (min-width: 1400px) and (max-width: 1918px) {
	.main-slider .image-layer:before {
			left: 353px;
	}



}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
	.main-slider .image-layer:before {
			left: 250px;
	}

	.main-slider__nav {
			right: 60px;
	}



}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-slider .image-layer:before {
			left: 0;
			right: 0;
			width: 100%;
	}

	.main-slider__nav {
			right: 60px;
	}

	.main-slider-three__get-quote {
			margin-top: -300px;
	}









}






@media only screen and (min-width: 768px) and (max-width: 991px) {
	.main-slider .image-layer:before {
			left: 0;
			right: 0;
			width: 100%;
	}

	.main-slider__nav {
			right: 60px;
	}

	.main-slider-two-image-layer {
			display: none;
	}

	.main-slider-two-shape-box {
			width: 100%;
	}

	.main-slider-two .swiper-slide {
			background-color: var(--brote-primary);
	}

	.main-slider-three__get-quote {
			margin-top: 0;
	}

	.main-slider-three__get-quote-inner {
			float: left;
	}










}



@media only screen and (max-width: 767px) {
	.main-slider .image-layer:before {
			left: 0;
			right: 0;
			width: 100%;
	}

	.main-slider__nav {
			display: none;
	}

	.main-slider__title br {
			display: none;
	}

	.main-slider__title {
			font-size: 59px;
			line-height: 69px;
			margin-top: 9px;
	}

	.main-slider-two-image-layer {
			display: none;
	}

	.main-slider-two-shape-box {
			width: 100%;
	}

	.main-slider-two .swiper-slide {
			background-color: var(--brote-primary);
	}

	.main-slider-two__title br {
			display: none;
	}

	.main-slider-two__title {
			font-size: 35px;
			line-height: 45px;
	}

	.main-slider-two__text br {
			display: none;
	}

	.main-slider-three__get-quote {
			margin-top: 0;
	}

	.main-slider-three__get-quote-inner {
			float: left;
	}

	.main-slider-three__title br {
			display: none;
	}

	.main-slider-three__title {
			font-size: 50px;
			line-height: 60px;
	}

	.main-slider-three__text br {
			display: none;
	}

	.main-slider-three__get-quote-inner {
			padding: 43px 20px 50px;
	}

	.main-slider-three__get-quote-btn {
			padding-left: 10px;
			padding-right: 10px;
	}

	.main-slider-three__get-quote-btn i {
			height: 30px;
			width: 30px;
			line-height: 30px;
			margin-left: 2px;
	}

	.main-slider-three__title span:before {
			display: none;
	}










}











/*--------------------------------------------------------------
# Main Menu All Responsice Css
--------------------------------------------------------------*/

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
	.main-menu-three {
			padding: 0 30px;
	}

	.stricky-header.main-menu-three {
			padding: 0 30px;
	}

	.main-menu-three__logo {
			margin-right: 50px;
	}






}


@media only screen and (min-width: 1200px) and (max-width: 1399px) {
	.main-menu-three {
			padding: 0 30px;
	}

	.stricky-header.main-menu-three {
			padding: 0 30px;
	}

	.main-menu-three__logo {
			margin-right: 50px;
	}

	.main-header-three__top-social {
			display: none;
	}











}





@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-header__top-details-list li:last-child {
			display: none;
	}

	.main-menu .mobile-nav__toggler {
			display: inline-block;
			padding: 20px 0;
	}

	.main-menu-two .mobile-nav__toggler {
			display: inline-block;
			padding: 43px 0;
			color: var(--brote-base);
	}

	.main-menu-two .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-three .mobile-nav__toggler {
			display: inline-block;
			padding: 41px 0;
			color: var(--brote-base);
	}

	.main-menu-three .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-three__logo {
			margin-right: 50px;
	}






}


@media only screen and (min-width: 768px) and (max-width: 991px) {
	.main-header__top-details-list {
			display: none;
	}

	.main-header__top-social-box {
			display: none;
	}

	.main-header__top-details {
			padding: 0px 0 66px;
	}

	.main-header__logo {
			position: relative;
			top: 0;
	}

	.main-header__logo>a {
			margin: 0 auto;
	}

	.main-menu .mobile-nav__toggler {
			display: inline-block;
			padding: 20px 0;
	}

	.main-header-two__top {
			display: none;
	}

	.main-menu-two .mobile-nav__toggler {
			display: inline-block;
			padding: 43px 0;
			color: var(--brote-base);
	}

	.main-menu-two .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-three .mobile-nav__toggler {
			display: inline-block;
			padding: 41px 0;
			color: var(--brote-base);
	}

	.main-menu-three .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-three__logo {
			margin-right: 50px;
	}

	.main-header-three__top-social {
			display: none;
	}

}




@media (max-width: 767px) {
	.main-header__top-details-list {
			display: none;
	}

	.main-header__top-social-box {
			display: none;
	}

	.main-header__top-details {
			padding: 0px 0 66px;
	}

	.main-header__logo {
			position: relative;
			top: 0;
	}

	.main-header__logo>a {
			margin: 0 auto;
	}

	.main-menu .mobile-nav__toggler {
			display: inline-block;
			padding: 20px 0;
	}

	.main-header-two__top {
			display: none;
	}

	.main-menu-two .mobile-nav__toggler {
			display: inline-block;
			padding: 43px 0;
			color: var(--brote-base);
	}

	.main-menu-two .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-two__right {
			display: none;
	}

	.main-menu-two__left {
			float: none;
	}

	.main-menu-two__logo {
			margin-right: 0;
	}

	.main-menu-two__main-menu-box {
			float: right;
	}

	.main-menu-three .mobile-nav__toggler {
			display: inline-block;
			padding: 41px 0;
			color: var(--brote-base);
	}

	.main-menu-three .mobile-nav__toggler:hover {
			color: var(--brote-primary);
	}

	.main-menu-three__right {
			display: none;
	}

	.main-menu-three__logo {
			margin-right: 0px;
	}

	.main-menu-three__left {
			float: none;
	}

	.main-menu-three__main-menu-three-box {
			float: right;
	}

	.main-menu-three {
			padding: 0 30px;
	}

}