/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --brote-font: "DM Sans", sans-serif;
  --brote-reey-font: "reeyregular";
  --brote-gray: #7b7d83;
  --brote-gray-rgb: 123, 125, 131;
  --brote-white: #ffffff;
  --brote-white-rgb: 255, 255, 255;
  --brote-base: #1239ac;
  --brote-base-rgb: 18, 57, 172;
  --brote-primary: #b1c2f5;
  --brote-primary-rgb: 255, 180, 0;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: var(--brote-font) !important;
  color: #ff010b !important;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}

body.locked {
  overflow: hidden;
}

a {
  text-decoration: none !important;
  color: var(--brote-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--brote-base);
  margin: 0;
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 2em;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--brote-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
/* .custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--brote-base, #a47c68);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--brote-base, #a47c68);
  opacity: 0.3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width 0.3s, height 0.3s, opacity 0.3s;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--brote-base, #a47c68);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: 0.4;
} */

.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none !important;
  background-color: var(--brote-base);
  color: var(--brote-white);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-left: 50px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
}

.thm-btn:hover {
  background-color: var(--brote-primary);
  color: var(--brote-white);
}

.thm-btn i {
  height: 40px;
  width: 40px;
  background-color: var(--brote-primary);
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-left: 15px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.thm-btn:hover i {
  background-color: var(--brote-base);
}

.section-title {
  position: relative;
  display: block;
  margin-top: -9px;
  margin-bottom: 47px;
}

.section-title__tagline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--brote-gray);
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 30px;
  margin-bottom: 3px;
}

.section-title__tagline::after {
  content: "";
  background-color: var(--brote-primary);
  width: 20px;
  height: 3px;
  margin-left: 10px;
}

.section-title__title {
  margin: 0;
  color: var(--brote-base);
  font-size: 50px;
  line-height: 61px;
  font-weight: 700;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select > .dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--brote-primary);
  color: #fff;
}

.bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--brote-base);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu > li > a:hover {
  background: var(--brote-white);
  color: var(--brote-base);
  cursor: pointer;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-image: url(../images/loader.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  width: 100%;
  background: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 99;
  position: relative;
}

.main-header__top {
  position: relative;
  display: block;
  background-color: transparent;
}

.main-header__top-social-box {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-bottom: 1px solid #e4e5ea;
  padding: 4.5px 0;
  z-index: 1;
}

.main-header__top-social-box-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 165px;
}

.main-header__top-social-text {
  font-size: 14px;
}

.main-header__top-social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header__top-social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--brote-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-social a:hover {
  color: var(--brote-base);
}

.main-header__top-social a + a {
  margin-left: 25px;
}

.main-header__top-details {
  position: relative;
  display: block;
  padding: 40px 0 66px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.main-header__top-details-inner {
  position: relative;
  display: block;
}

.main-header__logo {
  position: absolute;
  top: -80px;
  left: 0;
  z-index: 99;
}

.main-header__logo > a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 140px;
  width: 115px;
  background-color: white;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
}

.main-header__logo a > img {
  width: auto;
}

.main-header__top-details-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.main-header__top-details-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header__top-details-list li:before {
  position: absolute;
  top: 0;
  bottom: 7px;
  left: -30px;
  content: "";
  width: 1px;
  background-color: #e4e5ea;
}

.main-header__top-details-list li:first-child:before {
  display: none;
}

.main-header__top-details-list li + li {
  margin-left: 60px;
}

.main-header__top-details-list li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header__top-details-list li .icon span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  color: #6c757d;
}

.main-header__top-details-list li .icon span.icon-location {
  font-size: 34px;
}

.main-header__top-details-list li .text {
  margin-left: 20px;
}

.main-header__top-details-list li .text h5 {
  font-size: 14px;
  font-weight: 500;
  color: var(--brote-gray);
}

.main-header__top-details-list li .text h5 a {
  color: var(--brote-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-details-list li .text h5 a:hover {
  color: var(--brote-base);
}

.main-header__top-details-list li .text p {
  font-size: 16px;
  color: var(--brote-base);
  font-weight: 700;
}

.main-header__top-details-list li .text p a {
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-details-list li .text p a:hover {
  color: var(--brote-primary);
}

.main-menu {
  position: relative;
  display: block;
  margin-top: -33px;
}

.main-menu__wrapper {
  position: relative;
  display: block;
}

.main-menu__wrapper-inner {
  display: block;
  background-color: var(--brote-base);
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
}

.main-menu__left {
  display: block;
  float: left;
}

.main-menu__main-menu-box {
  display: block;
}

.main-menu__right {
  position: relative;
  display: block;
  float: right;
}

.main-menu__search-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 23px 0;
}

.main-menu__search-box:before {
  position: absolute;
  top: 14px;
  bottom: 14px;
  left: -30px;
  content: "";
  width: 1px;
  background-color: var(--brote-white);
  opacity: 0.1;
}

.main-menu__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  color: var(--brote-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__search:hover {
  color: var(--brote-primary);
}

.stricky-header.main-menu {
  padding: 0px 0px;
  background-color: var(--brote-white);
  margin-top: 0;
}

.stricky-header .main-menu__main-menu-box {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stricky-header .main-menu__wrapper {
  background-color: var(--brote-base);
}

.stricky-header .main-menu__wrapper-inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list > li > ul,
.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list > li > ul,
  .main-menu .main-menu__list > li > ul > li > ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list > li > ul,
  .stricky-header .main-menu__list > li > ul > li > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-left: 50px;
}

.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #b1c2f5;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 400;
}

.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.stricky-header .main-menu__list > li.current > a,
.stricky-header .main-menu__list > li:hover > a {
  color: var(--brote-white, #ffffff);
}

.main-menu .main-menu__list > li > ul,
.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 220px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease,
    -webkit-transform 700ms ease;
  z-index: 99;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.main-menu .main-menu__list > li > ul > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul > li > ul {
  display: none;
}

.main-menu .main-menu__list > li:hover > ul,
.main-menu .main-menu__list > li > ul > li:hover > ul,
.stricky-header .main-menu__list > li:hover > ul,
.stricky-header .main-menu__list > li > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list > li > ul > li,
.main-menu .main-menu__list > li > ul > li > ul > li,
.stricky-header .main-menu__list > li > ul > li,
.stricky-header .main-menu__list > li > ul > li > ul > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list > li > ul > li + li,
.main-menu .main-menu__list > li > ul > li > ul > li + li,
.stricky-header .main-menu__list > li > ul > li + li,
.stricky-header .main-menu__list > li > ul > li > ul > li + li {
  border-top: 1px solid rgba(var(--brote-base-rgb, 60, 53, 49), 0.1);
}

.main-menu .main-menu__list > li > ul > li > a,
.main-menu .main-menu__list > li > ul > li > ul > li > a,
.stricky-header .main-menu__list > li > ul > li > a,
.stricky-header .main-menu__list > li > ul > li > ul > li > a {
  font-size: 16px;
  line-height: 30px;
  color: var(--brote-gray);
  letter-spacing: 0;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 25px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .main-menu__list > li > ul > li:hover > a,
.main-menu .main-menu__list > li > ul > li > ul > li:hover > a,
.stricky-header .main-menu__list > li > ul > li:hover > a,
.stricky-header .main-menu__list > li > ul > li > ul > li:hover > a {
  background-color: var(--brote-base);
  color: var(--brote-white, #ffffff);
}

.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li > ul.right-align,
.stricky-header .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu-three__main-menu-three-box .main-menu__list > .megamenu,
.main-menu-two__main-menu-box .main-menu__list > .megamenu,
.main-menu__wrapper .main-menu__list > .megamenu {
  position: static;
}

.main-menu-three__main-menu-three-box .main-menu__list > .megamenu > ul,
.main-menu-two__main-menu-box .main-menu__list > .megamenu > ul,
.main-menu__wrapper .main-menu__list > .megamenu > ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-menu-three__main-menu-three-box .main-menu__list > .megamenu > ul > li,
.main-menu-two__main-menu-box .main-menu__list > .megamenu > ul > li,
.main-menu__wrapper .main-menu__list > .megamenu > ul > li {
  padding: 0 !important;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
  visibility: visible;
}

.stricky-header .main-menu__inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--brote-base);
  cursor: pointer;
}

.mobile-nav__buttons a + a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--brote-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--brote-white);
  cursor: pointer;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--brote-primary);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--brote-base);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--brote-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list > li > ul,
.mobile-nav__content .main-menu__list > li > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list > li > ul,
.mobile-nav__content .main-menu__list > li > ul > li > ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list > li:not(:last-child),
.mobile-nav__content .main-menu__list > li > ul > li:not(:last-child),
.mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > ul
  > li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list > li > a > .main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list > li > a,
.mobile-nav__content .main-menu__list > li > ul > li > a,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--brote-font, "Rubik", sans-serif);
  font-weight: 500;
  height: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__content
  .main-menu__list
  > li
  > a.expanded
  .mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > a.expanded
  .mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > ul
  > li
  > a.expanded {
  color: var(--brote-base);
}

.mobile-nav__content .main-menu__list > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a > button {
  width: 30px;
  height: 30px;
  background-color: var(--brote-primary);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list > li > a > button.expanded,
.mobile-nav__content .main-menu__list > li > ul > li > a > button.expanded,
.mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > ul
  > li
  > a
  > button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--brote-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--brote-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--brote-white);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__social a + a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--brote-primary);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--brote-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__contact li + li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--brote-primary);
}

.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--brote-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.home-showcase__inner {
  background-color: #fff;
  padding: 40px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}

.home-showcase__image {
  position: relative;
  background-color: var(--brote-base);
  overflow: hidden;
  border-radius: 10px;
}

.home-showcase__image > img {
  width: 100%;
  border-radius: 10px;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-showcase__image:hover > img {
  opacity: 0.75;
}

.home-showcase__image:hover .home-showcase__buttons {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.home-showcase__buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.home-showcase__buttons__item {
  padding: 10px 20px;
  width: 150px;
  text-align: center;
}

.home-showcase__buttons__item + .home-showcase__buttons__item {
  margin-top: 10px;
}

.home-showcase__title {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--brote-base);
  margin-top: 15px;
}

.mobile-nav__wrapper .home-showcase .row [class*="col-"] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0);
}

.mobile-nav__wrapper .home-showcase__title {
  color: var(--brote-white, #ffffff);
}

/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  position: relative;
  display: block;
}

.main-header-two__top {
  position: relative;
  display: block;
  background-color: var(--brote-base);
}

.main-header-two__top-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-header-two__top-address {
  position: relative;
  display: block;
  padding: 5px 0;
}

.main-header-two__top-address-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-two__top-address-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-two__top-address-list li + li {
  margin-left: 30px;
}

.main-header-two__top-address-list li .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-two__top-address-list li .icon span {
  font-size: 15px;
  color: var(--brote-primary);
}

.main-header-two__top-address-list li .text {
  margin-left: 10px;
}

.main-header-two__top-address-list li .text p {
  font-size: 14px;
  color: var(--brote-white);
}

.main-header-two__top-address-list li .text p a {
  color: var(--brote-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-two__top-address-list li .text p a:hover {
  color: var(--brote-primary);
}

.main-header-two__top-social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0a2e99;
  padding: 12.5px 30px;
}

.main-header-two__top-social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--brote-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-two__top-social a + a {
  margin-left: 30px;
}

.main-header-two__top-social a:hover {
  color: var(--brote-primary);
}

.main-menu-two {
  margin-top: 0;
}

.main-menu-two__wrapper {
  position: relative;
  display: block;
}

.main-menu-two__wrapper-inner {
  display: block;
}

.main-menu-two__left {
  display: block;
  float: left;
}

.main-menu-two__logo {
  position: relative;
  display: block;
  float: left;
  padding: 14px 0;
  margin-right: 70px;
}

.main-menu-two__main-menu-box {
  display: block;
  float: left;
}

.main-menu-two__right {
  position: relative;
  display: block;
  float: right;
}

.main-menu-two__search-call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu-two__search-box {
  position: relative;
  display: block;
  padding: 46px 0;
}

.main-menu-two__search-box:before {
  content: "";
  position: absolute;
  top: 38px;
  bottom: 38px;
  left: -30px;
  width: 1px;
  background-color: #e4e5ea;
}

.main-menu-two__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__search:hover {
  color: var(--brote-primary);
}

.main-menu-two__call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 28px 0;
  margin-left: 30px;
}

.main-menu-two__call-icon {
  position: relative;
  height: 60px;
  width: 60px;
  background-color: #eef0f6;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 26px;
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__call-icon:hover {
  background-color: var(--brote-base);
  color: var(--brote-white);
}

.main-menu-two__call-content {
  position: relative;
  margin-left: 15px;
  top: -4px;
}

.main-menu-two__call-sub-title {
  font-size: 14px;
}

.main-menu-two__call-number {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.main-menu-two__call-number a {
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__call-number a:hover {
  color: var(--brote-primary);
}

.main-menu-two .main-menu__list > li > a,
.stricky-header.main-menu-two .main-menu__list > li > a {
  color: var(--brote-gray);
  font-weight: 500;
}

.main-menu-two .main-menu__list > li.current > a,
.main-menu-two .main-menu__list > li:hover > a,
.stricky-header.main-menu-two .main-menu__list > li.current > a,
.stricky-header.main-menu-two .main-menu__list > li:hover > a {
  color: var(--brote-base);
}

.main-menu-two .main-menu__list > li,
.stricky-header.main-menu-two .main-menu__list > li {
  padding-top: 43px;
  padding-bottom: 43px;
}

/*--------------------------------------------------------------
# Navigations Three
--------------------------------------------------------------*/
.main-header-three {
  position: relative;
  display: block;
}

.main-menu-three {
  margin-top: 0;
  padding: 0 60px;
}

.main-menu-three__wrapper {
  position: relative;
  display: block;
}

.main-menu-three__left {
  float: left;
  display: block;
}

.main-menu-three__logo {
  display: block;
  float: left;
  margin-right: 240px;
  padding: 12px 0;
}

.main-menu-three__main-menu-three-box {
  display: block;
  float: left;
}

.main-menu-three__right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header-three__top-social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 60px;
  padding: 48.5px 0;
}

.main-header-three__top-social a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--brote-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-three__top-social a:hover {
  color: var(--brote-base);
}

.main-header-three__top-social a + a {
  margin-left: 30px;
}

.main-menu-three__search-call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu-three__search-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
  padding: 44px 0;
}

.main-menu-three__search-box:before {
  content: "";
  position: absolute;
  top: 36px;
  bottom: 36px;
  left: -30px;
  width: 1px;
  background-color: #e4e5ea;
}

.main-menu-three__search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__search:hover {
  color: var(--brote-primary);
}

.main-menu-three__call {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 26px 0;
}

.main-menu-three__call-icon {
  position: relative;
  height: 60px;
  width: 60px;
  background-color: #eef0f6;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 26px;
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__call-icon:hover {
  color: var(--brote-white);
  background-color: var(--brote-base);
}

.main-menu-three__call-content {
  position: relative;
  margin-left: 15px;
  top: -4px;
}

.main-menu-three__call-sub-title {
  font-size: 14px;
}

.main-menu-three__call-number {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.main-menu-three__call-number a {
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__call-number a:hover {
  color: var(--brote-primary);
}

.main-menu-three .main-menu__list > li > a,
.stricky-header.main-menu-three .main-menu__list > li > a {
  color: var(--brote-gray);
  font-weight: 500;
}

.main-menu-three .main-menu__list > li.current > a,
.main-menu-three .main-menu__list > li:hover > a,
.stricky-header.main-menu-three .main-menu__list > li.current > a,
.stricky-header.main-menu-three .main-menu__list > li:hover > a {
  color: var(--brote-base);
}

.main-menu-three .main-menu__list > li,
.stricky-header.main-menu-three .main-menu__list > li {
  padding-top: 41px;
  padding-bottom: 41px;
}

.stricky-header.main-menu-three {
  margin-top: 0;
  padding: 0 60px;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--brote-base);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--brote-base);
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.search-popup__content .thm-btn i {
  background-color: transparent;
  margin-left: 0;
}

.search-popup__content .thm-btn:hover {
  background-color: var(--brote-primary);
}

/*--------------------------------------------------------------
# Boxed Version
--------------------------------------------------------------*/
body.boxed-wrapper {
  background-color: #eef0f6;
}

body.boxed-wrapper .main-slider .image-layer {
  background-size: cover;
}

body.boxed-wrapper .contact-one__call {
  right: 245px;
}

.boxed-wrapper .page-wrapper {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  padding: 111px 0 116px;
  background-color: #eef0f6;
  z-index: 1;
  margin-top: -37px;
}

.page-header-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1382px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: -1;
}

.page-header-bg:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 377px;
  content: "";
  background-image: -ms-linear-gradient(
    0deg,
    #eef0f6 0%,
    rgba(238, 240, 246, 0) 100%
  );
}

.page-header-bubble {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-animation-name: zoominout;
  animation-name: zoominout;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: zoominout;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: zoominout;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-name: zoominout;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-name: zoominout;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: ease-in-out;
}

.page-header-bubble img {
  width: 100%;
}

.page-header__inner {
  position: relative;
  display: block;
  z-index: 15;
}

.page-header__inner h2 {
  font-size: 40px;
  color: #07ffdd;
  font-weight: 700;
  line-height: 50px;
}

.thm-breadcrumb {
  position: relative;
  display: block;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  color: var(--brote-gray);
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li + li {
  margin-left: 4px;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  color: var(--brote-gray);
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--brote-primary);
}

/* *Maintenance */

.service-details .container {
  max-width: 90%;
}

@keyframes zoominout {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }
}

