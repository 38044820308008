/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
  display: block;
  margin-top: 50px;
  background-color: #eef0f6;
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 530px;
  width: 100%;
  mix-blend-mode: luminosity;
}

/*--------------------------------------------------------------
# Information
--------------------------------------------------------------*/
.information {
  position: relative;
  display: block;
  z-index: 2;
}

.information__inner {
  position: relative;
  display: block;
  border-radius: 10px;
  background-color: #0a2e99;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  padding: 72px 0px 73px;
  margin-top: -105px;
  margin-left: 60px;
  margin-right: 60px;
  z-index: 1;
}

.information__inner:before {
  position: absolute;
  top: 15px;
  left: -60px;
  right: -60px;
  bottom: 15px;
  content: "";
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  z-index: -1;
}

.information__logo-box {
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.information__logo-box:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--brote-white);
  border-radius: 50%;
}

.information__logo-box a {
  z-index: 2;
}

.information__logo-box img {
  width: auto;
}

.information__border-1 {
  position: absolute;
  top: 76px;
  left: -90px;
  right: -90px;
  height: 3px;
  content: "";
  background-color: var(--brote-primary);
  z-index: -1;
}

.information__border-2 {
  position: absolute;
  top: 83px;
  left: -70px;
  right: -70px;
  height: 3px;
  content: "";
  background-color: var(--brote-base);
  z-index: -1;
}

.information__list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.information__list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.information__icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.information__icon span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 50px;
  color: var(--brote-base);
}

.information__content {
  margin-left: 20px;
}

.information__sub-title {
  font-size: 18px;
}

.information__number {
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 6px;
}

.information__number a {
  color: var(--brote-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.information__number a:hover {
  color: var(--brote-primary);
}

.information-two .information__inner {
  margin-top: 0;
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--brote-base);
  margin-top: -98px;
  z-index: 1;
}

.site-footer-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0.02;
}

.site-footer-shape-two {
  position: absolute;
  bottom: -20px;
  left: 47%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  mix-blend-mode: lighten;
  opacity: 0.1;
  z-index: -1;
  animation-name: zoom-fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoom-fade;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.site-footer-shape-two img {
  width: 100%;
}

/* Medium Screen */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.site-footer-shape-two {
		bottom: 20px;
	}
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.site-footer-shape-two {
		bottom: 40px;
	}
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
	.site-footer-shape-two {
		bottom: 90px;
		width: 100%;
		overflow: hidden;
	}
}

.site-footer__top {
  position: relative;
  display: block;
  padding: 192px 0 100px;
}

.footer-widget__about {
  position: relative;
  display: block;
}

.footer-widget__about-text-box {
  position: relative;
  display: block;
  margin-top: -1px;
}

.footer-widget__about-text {
  text-align: start !important;
  color: #b1c2f5;
  line-height: 32px;
}

.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 29px;
}

.site-footer__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--brote-white);
  font-size: 15px;
  background-color: #0a2e99;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.site-footer__social a:hover {
  color: var(--brote-base);
}

.site-footer__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--brote-white);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.site-footer__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.site-footer__social a + a {
  margin-left: 10px;
}

.footer-widget__links {
  position: relative;
  display: block;
}

.footer-widget__title {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--brote-white);
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 24px;
}

.footer-widget__links-list {
  position: relative;
  display: block;
}

.footer-widget__links-list li + li {
  margin-top: 6px;
}

.footer-widget__links-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #b1c2f5;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__links-list li a:hover {
  color: var(--brote-white);
}

.footer-widget__articles {
  position: relative;
  display: block;
  margin-right: -30px;
}

.footer-widget__articles-list {
  position: relative;
  display: block;
  margin-top: 9px;
}

.footer-widget__articles-list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-widget__articles-list li + li {
  margin-top: 20px;
}

.footer-widget__articles-img {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  max-width: 80px;
  width: 100%;
}

.footer-widget__articles-img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--brote-base-rgb), 0.5);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  border-radius: 10px;
  z-index: 1;
}

.footer-widget__articles-list li:hover .footer-widget__articles-img:before {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.footer-widget__articles-img img {
  width: 100%;
  border-radius: 10px;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.7s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.footer-widget__articles-list li:hover .footer-widget__articles-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.footer-widget__articles-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  color: var(--brote-white);
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  z-index: 2;
}

.footer-widget__articles-list li:hover .footer-widget__articles-img a {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.footer-widget__articles-content {
  margin-left: 20px;
  margin-top: -2px;
}

.footer-widget__articles-date {
  font-size: 14px;
  color: var(--brote-primary);
  line-height: 14px;
  margin-bottom: 7px;
}

.footer-widget__articles-sub-title {
  font-size: 16px;
  color: var(--brote-white);
  font-weight: 700;
  line-height: 30px;
}

.footer-widget__articles-sub-title a {
  color: var(--brote-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__articles-sub-title a:hover {
  color: var(--brote-primary);
}

.footer-widget__newsletter {
  position: relative;
  display: block;
  margin-left: 70px;
}

.footer-widget__newsletter-text {
  color: #b1c2f5;
  line-height: 28px;
  padding-top: 1px;
}

.footer-widget__newsletter-form {
  position: relative;
  display: block;
  margin-top: 31px;
}

.footer-widget__newsletter-input-box {
  position: relative;
  display: block;
}

.footer-widget__newsletter-input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 30px;
  padding-right: 60px;
  background-color: rgba(var(--brote-white-rgb), 0.1);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--brote-white);
}

.footer-widget__newsletter-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 50%;
  font-size: 15px;
  background-color: var(--brote-primary);
  color: var(--brote-white);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__newsletter-btn:hover {
  background-color: var(--brote-white);
  color: var(--brote-base);
}

.site-footer__bottom {
  position: relative;
  display: block;
  background-color: #0a2e99;
}

.site-footer__bottom-inner {
  position: relative;
  display: block;
  text-align: center;
  padding: 20px 0;
}

.site-footer__bottom-text {
  font-size: 16px;
  text-align: center !important;
  color: #b1c2f5;
}

.site-footer__bottom-text a {
  color: #b1c2f5;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-text a:hover {
  color: var(--brote-white);
}

.site-footer-two {
  margin-top: 0;
}

.site-footer-two .site-footer__top {
  padding: 112px 0 91px;
}
